let apiURL = "";
let appURL = "";
let ieURL = "";

switch (process.env.NODE_ENV) {
  case "production":
    apiURL = "https://api-plantaodevagas.tmp.pixaflow.com";
    appURL = "https://app-plantaodevagas.tmp.pixaflow.com";
    ieURL = "https://ie-plantaodevagas.tmp.pixaflow.com";
    break;

  case "development":
    apiURL = "http://localhost:8686";
    appURL = "http://localhost:3000";
    ieURL = "http://localhost:4000";
    break;

  default:
    apiURL = "https://api-plantaodevagas.tmp.pixaflow.com";
    appURL = "https://app-plantaodevagas.tmp.pixaflow.com";
    ieURL = "https://ie-plantaodevagas.tmp.pixaflow.com";
    break;
}

export { apiURL, appURL, ieURL };
