/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Router */
import { useNavigate } from "react-router-dom";

/* Material */
import {
  Box,
  Avatar,
  Toolbar,
  Typography,
  Hidden,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/* Icons */
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";

/* Services */
import { getUser } from "./util";

/* Styles */
import { classes, ContentPage, ContentMenu } from "./styles";

/* Components */
import { Alert } from "../Alert";
import { MenuDrawer } from "./components/MenuDrawer";
import { AppBar } from "./components/AppBar";
import { Drawer, DrawerMobile, DrawerHeader } from "./components/Drawer";

/* Custom Components */
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(68, 68, 68, 0.84)",
    border: "#e7e7e7",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
}));

/* Functions */
const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

const stringAvatar = (foto, name) => {
  let props = {};

  if (foto) {
    props = {
      src: `${foto}`,
    };
  } else {
    props = {
      children: `${String(name.split(" ")[0][0]).toUpperCase()}`,
      sx: {
        bgcolor: stringToColor(name),
      },
    };
  }

  return props;
};

export const Sidebar = (props) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState(null);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const load = useCallback(async () => {
    try {
      let unmounted = false;

      (async function () {
        try {
          await getUser().then((response) => {
            let { result } = response;

            let { nome, foto } = result;

            nome = nome !== null ? nome.split(" ")[0] : null;

            const data = {
              name: nome,
              picture: foto,
            };

            if (!unmounted) {
              setUser(data);
            }
          });
        } catch (error) {
          setUser(null);
          return null;
        }
      })();

      return () => {
        unmounted = true;
      };
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const toggleMobileMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <React.Fragment>
      <ContentPage>
        <Box className={classes.content}>
          <Box>
            <Drawer
              classes={{
                root: classes.drawerRoot,
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open={open}
            >
              <DrawerHeader>
                <IconButton onClick={toggleMenu}>
                  {open === true ? (
                    <ChevronLeftIcon
                      sx={{
                        color: (theme) => theme.palette.drawer.contrastText,
                      }}
                    />
                  ) : (
                    <ChevronRightIcon
                      sx={{
                        color: (theme) => theme.palette.drawer.contrastText,
                      }}
                    />
                  )}
                </IconButton>
              </DrawerHeader>

              <Divider
                sx={{
                  borderColor: (theme) => theme.palette.drawer.light,
                  marginBottom: (theme) => theme.spacing(1.5),
                }}
              />
              <ContentMenu>
                <MenuDrawer open={open} />
              </ContentMenu>
            </Drawer>

            <DrawerMobile
              classes={{
                root: classes.drawerRoot,
                paper: classes.drawerPaper,
              }}
              variant="temporary"
              open={mobileOpen}
              onClose={toggleMobileMenu}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <DrawerHeader>
                <IconButton onClick={toggleMobileMenu}>
                  {open === true ? (
                    <ChevronLeftIcon
                      sx={{
                        color: (theme) => theme.palette.drawer.contrastText,
                      }}
                    />
                  ) : (
                    <ChevronRightIcon
                      sx={{
                        color: (theme) => theme.palette.drawer.contrastText,
                      }}
                    />
                  )}
                </IconButton>
              </DrawerHeader>

              <Divider
                sx={{
                  borderColor: (theme) => theme.palette.drawer.light,
                  marginBottom: (theme) => theme.spacing(1.5),
                }}
              />
              <ContentMenu>
                <MenuDrawer open={open} />
              </ContentMenu>
            </DrawerMobile>
          </Box>

          <Box width="100%" sx={{ overflowX: "hidden" }}>
            <AppBar open={open} position="relative" color="inherit">
              <Toolbar>
                <Box display="flex" alignItems="center" flexGrow={1}>
                  <Hidden smDown>
                    {user ? (
                      <React.Fragment>
                        <Typography
                          component="h1"
                          variant="h6"
                          color="inherit"
                          display="flex"
                          noWrap
                        >
                          Olá,
                          <Typography
                            component="div"
                            variant="h6"
                            color=""
                            noWrap
                            pl={1}
                            sx={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "150px",
                            }}
                          >
                            {user ? user.name : ""}
                          </Typography>
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </Hidden>
                  <Hidden smUp>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleMobileMenu}
                      edge="start"
                      sx={{ mr: 1, ml: 0 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                </Box>
                <Box>
                  {user ? (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "10pt",
                            }}
                          >
                            Administrativo | Plantão de Vagas
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "8pt",
                            }}
                          >
                            {user.name}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <Avatar
                        alt={user.name}
                        onClick={() => navigate("/conta")}
                        className={classes.avatar}
                        {...stringAvatar(user.picture, user.name)}
                      />
                    </HtmlTooltip>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Box>
              </Toolbar>
            </AppBar>

            <Box className={classes.page}>
              <Box className={classes.pageContainer}>
                {openError ? (
                  <Box m={4}>
                    <Alert message={messageError} open={openError} />
                  </Box>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {props.children}
              </Box>
            </Box>
          </Box>
        </Box>
      </ContentPage>
    </React.Fragment>
  );
};
