import DashboardIcon from "@mui/icons-material/Dashboard";
import BadgeIcon from "@mui/icons-material/Badge";
import SchoolIcon from "@mui/icons-material/School";

import EmailIcon from "@mui/icons-material/Email";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

export const itemsStart = {
  name: "DASHBOARD",
  items: [
    {
      path: "/inicio",
      icon: DashboardIcon,
      label: "Página Inicial",
    },
  ],
};

export const itemsEducation = {
  name: "FORMAÇÃO",
  items: [
    {
      path: "/area",
      icon: BadgeIcon,
      label: "Área",
    },
    {
      path: "/graduacao",
      icon: SchoolIcon,
      label: "Graduação",
    },
  ],
};

export const itemsMenu = {
  name: "PRINCIPAL",
  items: [],
};

export const itemsSettings = {
  name: "FERRAMENTAS",
  items: [
    // {
    //   path: "/mala-direta",
    //   icon: EmailIcon,
    //   label: "Mala Direta",
    // },
    {
      path: "/conta",
      icon: SettingsIcon,
      label: "Configurações",
    },
    {
      path: "/logout",
      icon: LogoutIcon,
      label: "Sair",
    },
  ],
};
